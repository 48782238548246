<template>
  <div>
    <router-link active-class="is-active" :to="{ name: `dashboard_inbox` }">
      <span class="has-text-weight-light is-size-3">Back to Inbox</span>
    </router-link>

    <div v-if="topic">
      <div
        class="my-2 is-flex is-justify-content-flex-start is-align-items-center"
      >
        <span class="pr-1">from</span><UserPill :user="topic.author" />
        <span class="px-1">sent</span><InlineDate :d="topic.created_at" />
      </div>

      <div class="is-flex is-justify-content-space-between">
        <div class="is-size-3-desktop is-size-4-touch has-text-weight-light mb-3">
          {{ topic.title }}
        </div>
      </div>

      <TipTap
        class="is-size-6"
        :value="topic.body"
        @updated="handleUpdatedBody($event, topic)"
      />

      <PostCard
        v-for="p in posts"
        :id="p.id"
        :key="p.id"
        :post="p"
        :max-depth="0"
        :style="`margin-left: ${p.depth * 2}rem;`"
        @start-reply="setupReplyToPost($event)"
      />
      <b-button
        :disabled="isShowingReplyForm"
        type="is-primary"
        label="Reply"
        @click="isShowingReplyForm = true"
      />
      <PostCard
        v-if="isShowingReplyForm"
        class="elevation-1 my-2 boxed"
        :post="null"
        :topic="topic"
        @created="isShowingReplyForm = false"
        @cancel="isShowingReplyForm = false"
      />
    </div>
  </div>
</template>

<script>
import {
  FORUMS_GET_TOPIC_BY_PK,
  LIST_TOPIC_POSTS
} from "@/models/forums/operations.gql";
import UserPill from "@/components/common/UserPill.vue";
import Bookmark from "@/components/common/Bookmark.vue";
import TipTap from "@/components/forms/TipTap.vue";
import InlineDate from "@/components/common/InlineDate.vue";
import PostCard from "@/components/forums/PostCard.vue";

export default {
  name: "InboxTopic",
  components: {
    UserPill,
    Bookmark,
    TipTap,
    InlineDate,
    PostCard
  },
  data: () => ({
    isShowingReplyForm: false
  }),
  apollo: {
    topic: {
      query: FORUMS_GET_TOPIC_BY_PK,
      variables() {
        return {
          topic_id: this.$route.params.topic_id
        };
      }
    },
    posts: {
      query: LIST_TOPIC_POSTS,
      variables() {
        return {
          topic_id: this.$route.params.topic_id,
          orderBy: { created_at: "asc" }
        };
      }
    }
  }
};
</script>
